import React, { useCallback, useMemo, useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import axios from "axios";
import {
  Button, Card, CardHeader, Container, Row, Col
} from "reactstrap";
import {
  Modal, TextField, IconButton, Avatar, Box, Typography
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { useDropzone } from 'react-dropzone';
import { baseUrl } from "config";

export const ServicesCategories = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", img: null, active: false });
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);

  const queryClient = useQueryClient();

  const fetchServiceCategories = async () => {
    const { data } = await axios.get(`${baseUrl}/admin/get-all-essentials`);
    return data?.data?.servicesCategories;
  };

  const { data: serviceCategories, refetch } = useQuery("serviceCategories", fetchServiceCategories);

  const mutation = useMutation(
    (newData) => {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      };
      if (isEditing) {
        return axios.put(`${baseUrl}/services/service-categories/${editingId}`, newData, config);
      } else {
        return axios.post(`${baseUrl}/services/create-categories-services`, newData, config);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("serviceCategories");
        setOpen(false);
        setFormData({ name: "", img: null, active: false });
        setIsEditing(false);
        setEditingId(null);
      },
    }
  );

  const deleteCategory = async (id) => {
    await axios.delete(`${baseUrl}/services/delete-categories-services/${id}`);
    refetch();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEdit = (category) => {
    setFormData({ name: category.name, img: null, active: category.active });
    setIsEditing(true);
    setEditingId(category._id);
    handleOpen();
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCategory(id);
        Swal.fire("Deleted!", "Your category has been deleted.", "success");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFormData({ ...formData, img: acceptedFiles[0] });
  }, [formData]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('archivo', formData.img); // Asegúrate de que este campo se llama 'archivo'
    mutation.mutate(formDataToSend);
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) => row.img ? <Avatar src={`${baseUrl}/${row.img}`} /> : "No image",
    },
    {
      name: "Active",
      selector: (row) => (row.active ? "Yes" : "No"),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          {/* <IconButton onClick={() => handleEdit(row)}>
            <EditIcon />
          </IconButton> */}
          <IconButton onClick={() => handleDelete(row._id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ], []);

  return (
    <Container className="mt-15">
      <Row className="mt-10">
        <Col className="mt-5" >
          <Card  className="mt-5">
            <CardHeader  className="mt-5">
              <Button color="primary" onClick={handleOpen}>Add New Category</Button>
            </CardHeader>
            <DataTable
              columns={columns}
              data={serviceCategories || []}
              progressPending={!serviceCategories}
              pagination
            />
          </Card>
        </Col>
      </Row>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style, width: 400 }}>
          <Typography variant="h6" component="h2">
            {isEditing ? "Edit Category" : "Add New Category"}
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <div {...getRootProps()} style={{ border: '2px dashed gray', padding: '20px', textAlign: 'center' }}>
              <input {...getInputProps()} />
              {formData.img ? formData.img.name : "Drag 'n' drop some files here, or click to select files"}
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              {isEditing ? "Save Changes" : "Add Category"}
            </Button>
          </form>
        </Box>
      </Modal>
    </Container>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
