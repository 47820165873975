import { Button, Card, CardHeader, Col, Container, Row } from "reactstrap";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import Header from "components/Headers/Header";
import axios from "axios";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import DataTable from "react-data-table-component";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { baseUrl } from "config";

function getOrders() {
  return axios.get(baseUrl + "/admin/get-orders");
}

function getAppiontments() {
  return axios.get(baseUrl + "/admin/get-appointments");
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Orders = () => {
  const { data: orders, refetch } = useQuery("get-orders", getOrders);
  const { data: appointments } = useQuery("get-appointments", getAppiontments);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ordersColumns = [
    {
      name: "Id",
      selector: (row) => row.orderId || "NA",
    },
    {
      name: "Total Amount",
      selector: (row) => row.total_amount || "NA",
    },
    {
      name: "Status",
      selector: (row) => row.order_status || "NA",
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          {/* <EditIcon style={{ color: "#5e72e4", marginRight: "1rem" }} /> */}
          <DeleteIcon
            style={{ color: "#5e72e4", marginRight: "1rem", cursor: "pointer" }}
            onClick={() => {
              axios
                .post(baseUrl + "/admin/delete-essential", {
                  type: "ORDER",
                  id: row?._id,
                })
                .then((data) => {
                  alert("Deleted successfully");
                  refetch();
                })
                .catch((err) => alert("Something went wrong."));
            }}
          />
        </>
      ),
    },
  ];
  
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col"></div>
                  <div className="col text-right">
                    {/* <Button color="primary" onClick={handleOpen} size="sm">
                                            Add
                                        </Button>
                                        <MyModal
                                            open={open}
                                            handleClose={handleClose}
                                            value={value}
                                        /> */}
                  </div>
                </Row>
              </CardHeader>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                  >
                    <Tab
                      label="Orders"
                      style={{
                        fontWeight: "bold",
                      }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Appointments"
                      style={{
                        fontWeight: "bold",
                      }}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <DataTable
                    responsive
                    columns={ordersColumns}
                    data={orders?.data?.data}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <DataTable
                    responsive
                    columns={appointmentsColumns}
                    data={appointments?.data?.data}
                  />
                </TabPanel>
              </Box>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Orders;

const appointmentsColumns = [
  {
    name: "Booking Id",
    selector: (row) => row.bookingId || "NA",
  },
  {
    name: "Booking Status",
    selector: (row) => row.booking_status || "NA",
  },
  {
    name: "Total Amount",
    selector: (row) => row.total_amount || "NA",
  },
  // {
  //     name: "Actions",
  //     selector: (row) => (
  //         <>
  //             <EditIcon style={{ color: "#5e72e4", marginRight: "1rem" }} />
  //             <DeleteIcon
  //                 style={{ color: "#5e72e4", marginRight: "10rem", cursor: "pointer" }}
  //                 onClick={() => {
  //                     axios
  //                         .post(
  //                             baseUrl + "/admin/delete-essential",
  //                             {
  //                                 type: "MAKER",
  //                                 id: row?._id,
  //                             }
  //                         )
  //                         .then((data) => {
  //                             alert("Deleted successfully");
  //                             getAllAcounts();
  //                         })
  //                         .catch((err) => alert("Something went wrong."));
  //                 }}
  //             />
  //         </>
  //     ),
  // },
];
