import { Button, Col, Container, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Header from "components/Headers/Header";
import axios from "axios";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import DataTable from "react-data-table-component";
import { baseUrl } from "config";
import DeleteIcon from "@mui/icons-material/Delete";
import { Card, CardHeader, CardContent, CardMedia } from "@mui/material";
import { CarSelect } from "components/ui/CarSelect";

function getServices() {
  return axios.get(baseUrl + "/admin/get-services");
}

function getProducts() {
  return axios.get(baseUrl + "/admin/get-products");
}
function getAllAcounts() {
  return axios.get(baseUrl + "/admin/get-all-essentials");
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExpandedComponentProdcuts = ({ data }) => (
  <Card sx={{ maxWidth: 700, margin: "3rem"}}>
    <CardMedia
      component="img"
      height="194"
      image={baseUrl + "/" + data.productImg}
      alt="Prodcut image"
    />
    <CardContent>
      <div
        style={{
          display: "flex",
        }}
      >
        <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">
          Description :
        </p>
        <p>{data.description}</p>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">
          Model:
        </p>
        <p>{data.model?.name}</p>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">
          Maker:
        </p>
        <p>{data.maker?.name}</p>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">
          In Stock:
        </p>
        <p>{data.inStock ? "Yes" : "No"}</p>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">
          Category:
        </p>
        <p>{data.category?.name}</p>
      </div>
    </CardContent>
  </Card>
);

const ExpandedComponentServices = ({ data }) => (
  <Card sx={{ maxWidth: 700, margin: "3rem"}}>
    <CardMedia
      component="img"
      height="194"
      image={baseUrl + "/" + data.coverImg}
      alt="Service image"
    />
    <CardContent>
      <div
        style={{
          display: "flex",
        }}
      >
        <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">
          Description :
        </p>
        <p>{data.description}</p>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">
          Category:
        </p>
        <p>{data?.category?.name}</p>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">
          Makers:
        </p>
        {data?.makers?.map((item) => {
          return (
              <p style={{
                margin:"0 0.3rem",
                display:"block"
              }}>{item.name}</p>
          );
        })}
      </div>
    </CardContent>
  </Card>
);

const Products = () => {
  const { data: services } = useQuery("get-services", getServices);
  const { data: products } = useQuery("get-products", getProducts);
  
  const { data : essential } = useQuery("get-all-users", getAllAcounts);
  
  const [carsAll, setCarsAll] = useState(products?.data?.data)
  const [value, setValue] = React.useState(0);
  const [row, setRow] = React.useState("");
  const [filterCars, setFilterCars] = useState({
    marca: '',
    modelo:''
  })
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (setName) => {
    setOpen(false);
    setName && setName();
  };

  const productsColumns = [
    {
      name: "Name",
      selector: (row) => row.name || "NA",
      grow: 3,
    },
    {
      name: "Price",
      selector: (row) => row.price || "NA",
    },
    {
      name: "Status",
      selector: (row) => row.status || "NA",
    },
    {
      name: "Actions",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.isBlocked ? (
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                handleOpen();
                setRow(row);
              }}
            >
              Unblock
            </Button>
          ) : (
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                handleOpen();
                setRow(row);
              }}
            >
              {""}
              Block
            </Button>
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          {/* <EditIcon style={{ color: "#5e72e4", marginRight: "1rem" }} /> */}
          <DeleteIcon
            style={{ color: "#5e72e4", marginRight: "1rem", cursor: "pointer" }}
            onClick={() => {
              axios
                .post(baseUrl + "/admin/delete-essential", {
                  type: "PRODUCT",
                  id: row?._id,
                })
                .then((data) => {
                  alert("Deleted successfully");
                  // getAllAcounts();
                })
                .catch((err) => alert("Something went wrong."));
            }}
          />
        </>
      ),
    },
  ];

  const servicesColumns = [
    {
      name: "Name",
      selector: (row) => row.name || "NA",
    },
    {
      name: "Price",
      selector: (row) => row.price.toLocaleString("en", {
        style: "currency",
        currency: "MXN"
      }) || "NA",
    },
    {
      name: "Status",
      selector: (row) => row.status || "NA",
    },
    {
      name: "Actions",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.isBlocked ? (
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                handleOpen();
                setRow(row);
              }}
            >
              Unblock
            </Button>
          ) : (
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                handleOpen();
                setRow(row);
              }}
            >
              {""}
              Block
            </Button>
          )}
        </div>
      ),
    },
  ];

  const onChangeMarcasModel = (value,inp) => {

    setFilterCars({
      ...filterCars,
      [inp]:value
    })
    
    if (inp === 'marca') {
      let m = matchMaker(products?.data?.data,value);
     
      setCarsAll(m)
    }else{
      let m = matchModel(products?.data?.data,value);
      setCarsAll(m)
    }


   
  }

  const matchMaker = (productos,valueMaker) => {

    let itemData;
    
    let marca = productos.filter((item) => {
  
      itemData = item.maker ? item?.maker?._id : '';
  
      let searchTextData = valueMaker;
     
      // let match = matchesForModel(searchTextData2,item,valueYear);
    
      return itemData.indexOf(searchTextData) > -1 ;
    })
  
    return marca;
  }
  
  const matchModel = (productos,valueModel,valueYear) => {
  
    
    let itemData;
    let itemModel;
   
    const marca = productos.filter((item) => {
      itemData = item.maker ? item?.maker?._id : '';
  
      let searchTextData = filterCars.marca;
      
      
     
      
     
      
      
      return itemData.indexOf(searchTextData) > -1   ;
  })
  
    const modelo = marca.filter((item) => {
      itemModel = item.model ? item?.model?._id : '';
      let searchTextData = valueModel;
      
      
      return itemModel.indexOf(searchTextData) > -1  ;
  })
  
    return modelo;
  }

  const resetProducts = () => {
    setFilterCars({
      marca:'',
      modelo:''
    })

    setCarsAll(products?.data?.data)
  }

  const saveData = () => {
    
    setCarsAll(products?.data?.data)
  }

  useEffect(() => {
    console.log('no save');
    if (!carsAll) {
      console.log('save');
       saveData()
    }
   
  }, [products?.data?.data])
  
  
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <MyModal
                open={open}
                handleClose={handleClose}
                row={row}
                value={value}
              />
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col"></div>
                  <div className="col text-right">
                    {/* <Button color="primary" onClick={handleOpen} size="sm">
                      Add
                    </Button> */}
                    <MyModal
                      open={open}
                      handleClose={handleClose}
                      value={value}
                      row={row}
                    />
                  </div>
                </Row>
              </CardHeader>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                  >
                    <Tab
                      label="Products"
                      style={{
                        fontWeight: "bold",
                      }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Services"
                      style={{
                        fontWeight: "bold",
                      }}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <Box>
                  {
                    essential && (
                      <CarSelect 
                      marcas={essential?.data?.data?.makers} 
                      modelos={essential?.data?.data?.models}
                      onChangeMarcasModel={onChangeMarcasModel}
                      valueMaker={filterCars.marca}
                      valueModel={filterCars.modelo}
                      />
                    )
                  }

            <Button
              color="primary"
              size="sm"
              onClick={resetProducts}
            >
              Reset
            </Button>
                  
                </Box>

                <TabPanel value={value} index={0}>
                  
                       <DataTable
                    responsive
                    columns={productsColumns}
                    data={carsAll}
                    expandableRows
                    expandableRowsComponent={ExpandedComponentProdcuts}
                    
                  />
                    
                 
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <DataTable
                    responsive
                    columns={servicesColumns}
                    data={services?.data?.data}
                    expandableRows
                    expandableRowsComponent={ExpandedComponentServices}
                  />
                </TabPanel>
              </Box>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Products;

const MyModal = ({ open, handleClose, row, value }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  function changeStatus() {
    // console.log(row._id, reason);
    if (row.isBlocked) {
      return axios.post(baseUrl + "/admin/block-product", {
        productId: row._id,
        reason: reason,
      });
    } else {
      return axios.post(baseUrl + "/admin/unblock-product", {
        productId: row._id,
        reason: reason,
      });
    }
  }

  const [reason, setReason] = React.useState("");
  //   const {
  //     data,
  //     refetch,
  //     isLoading,
  //     remove,
  //     isFetching,
  //     error,
  //     isError,
  //     isSuccess,
  //   } = useQuery("changeStatusProdcuts", changeStatus, {
  //     enabled: false,
  //     refetchOnMount: false,
  //     refetchOnWindowFocus: false,
  //   });
  // console.log("Status", isFetching, error, isSuccess, isError, data);
  function handleProductStatus() {
    if (row.isBlocked) {
      axios
        .post(baseUrl + "/admin/unblock-product", {
          productId: row._id,
          reason: reason,
        })
        .then((data) => {
          alert("Status changed successfully");
          handleClose(setReason);
        })
        .catch((err) => {
          alert("Something went wrong");
          handleClose(setReason);
        });
    } else {
      axios
        .post(baseUrl + "/admin/block-product", {
          productId: row._id,
          reason: reason,
        })
        .then((data) => {
          alert("Status changed successfully");
          handleClose(setReason);
        })
        .catch((err) => alert("Something went wrong"));
    }
  }
  function handleServiceStatus() {
    if (row.isBlocked) {
      axios
        .post(baseUrl + "/admin/unblock-service", {
          serviceId: row._id,
          reason: reason,
        })
        .then((data) => {
          alert("Status changed successfully");
          handleClose(setReason);
        })
        .catch((err) => alert("Something went wrong"));
    } else {
      axios
        .post(baseUrl + "/admin/block-service", {
          serviceId: row._id,
          reason: reason,
        })
        .then((data) => {
          alert("Status changed successfully");
          handleClose(setReason);
        })
        .catch((err) => alert("Something went wrong"));
    }
  }
  return (
    <Modal
      open={open}
      onClose={() => handleClose(setReason)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          style={{ marginBottom: "15px" }}
          variant="h6"
          component="h2"
        >
          {row?.isBlocked ? "Ublock" : "Block"}
        </Typography>
        {/* {isFetching && <p>Loading...</p>}
        {isError && <p>{error?.response?.data?.message}</p>}
        {isSuccess && <p>{`Status changes successfully`}</p>} */}
        <TextField
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          id="outlined-basic"
          label="Enter reason"
          variant="outlined"
        />
        <br />
        <Button
          color="primary"
          style={{ float: "right", marginTop: "15px" }}
          size="sm"
          // onClick={() => refetch()}
          onClick={() => {
            if (value === 0) handleProductStatus();
            else if (value === 1) handleServiceStatus();
          }}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};
