import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

const AdminNavbar = (props) => {
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <Link
                to={"/admin/change-password"}
                className="h4 mb-0 text-white d-none d-lg-inline-block mx-4"
              >
                Change Password
              </Link>
              <Link
                className="h4 mb-0 text-white d-none d-lg-inline-block cursor-pointer"
                to={"/"}
                onClick={() => props.setLoggedIn(false)}
              >
                Sign out
              </Link>
            </FormGroup>
          </Form>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
