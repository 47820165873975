import { Button, Card, CardHeader, Col, Container, Row } from "reactstrap";
import React from "react";
import { useQuery } from "react-query";
import Header from "components/Headers/Header";
import axios from "axios";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

import DataTable from "react-data-table-component";

import { baseUrl } from "../../config.js";

function getFees() {
  return axios.get(baseUrl + "/admin/get-fee");
}

const Fees = () => {
  const feesColumns = [
    {
      name: "Delivery fee",
      selector: (row) => row.delivery_fee + " MXN PER KM" || "NA",
    },
    {
      name: "Besseri comission",
      selector: (row) => row.besseri_comission + " %" || "NA",
    },
    {
      name: "Actions",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              handleOpen();
              setRow(row);
            }}
          >
            {""}
            Edit
          </Button>
        </div>
      ),
    },
  ];
  const [value, setValue] = React.useState(0);
  const [row, setRow] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (setName) => {
    setOpen(false);
    setName && setName();
  };
  const { data: fees } = useQuery("get-fees", getFees);
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader className="border-0"></CardHeader>
              <DataTable
                responsive
                columns={feesColumns}
                data={fees?.data?.data}
              />
              <MyModal
                open={open}
                handleClose={handleClose}
                value={value}
                row={row}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Fees;

const MyModal = ({ open, handleClose, row, value }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [fees, setFees] = React.useState("");
  const [comission, setComission] = React.useState("");
  const obj = {
    delivery_fees: fees,
    besseri_comission: comission,
    feeId: row?._id,
  };
  function changeFee() {
    axios
      .patch(baseUrl + "/admin/update-fee", obj)
      .then((data) => {
        alert("Fee changed successfully");
        handleClose(setFees);
        setComission("");
      })
      .catch((err) => {
        alert("Something went wrong");
        handleClose(setFees);
        setComission("");
      });
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose(setFees)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          style={{ marginBottom: "15px" }}
          variant="h6"
          component="h2"
        >
          Change Fee
        </Typography>
        <TextField
          value={fees}
          type="number"
          onChange={(e) => setFees(e.target.value)}
          id="outlined-basic"
          label="Enter delivery fees per km"
          variant="outlined"
        />
        <br />
        <br />
        <TextField
          value={comission}
          type="number"
          onChange={(e) => setComission(e.target.value)}
          id="outlined-basic"
          label="Enter besseri comission %"
          variant="outlined"
        />
        <br />
        <Button
          color="primary"
          style={{ float: "right", marginTop: "15px" }}
          size="sm"
          onClick={() => {
            changeFee();
          }}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};
