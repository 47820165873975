import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'


export const CarSelect = ({ marcas, modelos, onChangeMarcasModel, valueModel, valueMaker }) => {
    const [makers, setMakers] = useState([]);
    const [models, setModels] = useState([]);


    const handleChangeMaker = (event) => {

        let value = event.target.value;
        let inp = event.target.name;
        setMakers(value)
        onChangeMarcasModel(value, inp)

        let m = modelos.filter((item) => item.makerId === value);

        setModels(m)
    };

    const handleChangeModel = (event) => {

        let value = event.target.value;
        let inp = event.target.name;


        // setModels(value)
        onChangeMarcasModel(value, inp)
    };



    return (
        <>
            <Grid container rowSpacing={1}>
               
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 320 }} >
                        <InputLabel id="demo-simple-select-standard-label">Marca</InputLabel>
                        <Select
                            labelId="marca"
                            id="marcas"
                            value={valueMaker}
                            onChange={handleChangeMaker}
                            label="Marcas"
                            name='marca'
                        >
                            {
                                marcas.map((item) => <MenuItem
                                    value={item._id}
                                    key={item._id}
                                >
                                    {item.name}
                                </MenuItem>
                                )
                            }


                        </Select>
                    </FormControl>
               
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 320 }}>
                        <InputLabel id="modelo">Modelo</InputLabel>
                        <Select
                            labelId="modelo"
                            id="modelos"
                            value={valueModel}
                            onChange={handleChangeModel}
                            label="Modelos"
                            name='modelo'
                        >
                            {
                                models.map((item) => <MenuItem
                                    value={item._id}
                                    key={item._id}
                                >
                                    {item.name}
                                </MenuItem>
                                )
                            }


                        </Select>
                    </FormControl>
                

            </Grid>





        </>
    )
}
