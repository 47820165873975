import axios from 'axios';
import Header from 'components/Headers/Header'
import { baseUrl } from 'config';
import React from 'react'
import {  QueryCache, useQuery, useQueryClient } from 'react-query';
import { Container } from 'reactstrap'
import { Table, Button } from 'reactstrap';


function getPreRegistration() {
    return axios.get(baseUrl + "/admin/pre-registration");
  }


export const PreRegistration = () => {
  const queryClient = useQueryClient()
    const { data, isLoading, isError } = useQuery("get-pre-registration", getPreRegistration);
    
    const handleAuthorize = async (id, authorized) => {
      try {
       
        const url = `${baseUrl}/admin/pre-registration/${id}`
        const response = await axios.patch(url, { authorized });
        console.log(response);
        queryClient.invalidateQueries('get-pre-registration')
  
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    
    if (isError) {
        return <div>Error al cargar los datos</div>;
    }

   
    if (!data || !data.data || data.data.length === 0) {
        return <div>No hay resultados para mostrar</div>;
    }
    const response = data.data;
   

    return (
        <>
            <Header/>
            <Container className="mt-4" fluid>

                <DataTable data={response.data} handleAuthorize={handleAuthorize} />

            </Container>
        </>
  )
}

const DataTable = ({ data, handleAuthorize }) => {
  

  return (
    <div className="table-responsive">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Store Name</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Address</th>
            <th>Type Store</th>
            <th>Authorized</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            console.log(item.authorized);
            return (
              <tr key={item._id} style={{marginTop:10}} >
                <td>{index + 1}</td>
                <td>{item.storeName}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.phone_number}</td>
                <td>{item.address}</td>
                <td>{item.type_store.join(', ')}</td>
                <td style={{ textTransform: 'capitalize' }}>{item.authorized}</td>
                <td>
                 {
                  item.authorized !== 'accepted' && (
                     <>
                     <button onClick={() => handleAuthorize(item._id, 'accepted')} className="btn btn-success mr-2">
                    <i className="fas fa-check" />
                  </button>
                  <button onClick={() => handleAuthorize(item._id, 'rejected')} className="btn btn-danger">
                    <i className="fas fa-times" />
                  </button>
                     </>
                  )
                 }
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
};
  