import axios from "axios";
import { baseUrl } from "config";
import { useState } from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import Login from "views/examples/Login";
import { Redirect } from "react-router-dom";
import ChangePassword from "views/examples/ChangePassword";

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  
  function handleClick(email, password) {
    
    const url = baseUrl + "/user/admin-login";
    axios
      .post(url, {
        email,
        password,
      })
      .then((data) => setLoggedIn(true))
      .catch((err) => {
        console.log(err);
        alert("Password or username is incorrect")
      });
  }
  
 



  return (
    <>
     <LocalizationProvider dateAdapter={AdapterMoment}>
      {loggedIn ? (
        <Switch>
          <Route path="/admin/change-password" component={ChangePassword} />
          <Route
            path="/admin"
            render={(props) => (
              <AdminLayout {...props} setLoggedIn={setLoggedIn} />
            )}
          />
          <Redirect from="/" to="/admin/index" />
        </Switch>
      ) : (
        <Login handleClick={handleClick} />
      )}
      </LocalizationProvider>
    </>
  );
};

export default App;
