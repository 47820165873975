import { Button, Card, CardHeader, Col, Container, Row } from "reactstrap";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import Header from "components/Headers/Header";
import axios from "axios";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import DataTable from "react-data-table-component";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit"
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { baseUrl } from "config";
import { Avatar, IconButton, Input } from "@mui/material";
import { CalendarMonth, Camera, PhotoCamera } from "@mui/icons-material";

import Swal from "sweetalert2";


function getAllAcounts() {
  return axios.get(baseUrl + "/admin/get-all-essentials");
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Essentials = () => {
  const { data,refetch } = useQuery("get-all-users", getAllAcounts);
  const [value, setValue] = React.useState(0);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isYears, setIsYears] = useState(false)
  const [itemEdit, setItemEdit] = useState('')
  const handleChange = (event, newValue) => {
    
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (remove, setName) => {
    setOpen(false);
    remove && remove();
    setName && setName("");
  };

  const handleCloseEdit = (remove, setName) => {
    setOpenEditModal(false);
    setItemEdit('')
  };

  useEffect(() => {
    // Aquí llamamos getAllAcounts() cuando isYears es true
    if (!isYears) {
      console.log('cambio');
      getAllAcounts();
    }
  }, [isYears]);


  const [selectedRows, setSelectedRows] = useState([]);



	const handleButtonClick = (item) => {
		setItemEdit(item)
		setOpenEditModal(true)
	};

	const handleChange2 = useCallback((state,newValue) => {
    setValue(newValue)
		setSelectedRows(state.selectedRows);
	}, []);

	const columns = useMemo(
		() => [
      {
        name: "Name",
        selector: (row) => row.name,
      },
      {
        name: "Disabled",
        selector: (row) => row.disabled.toString(),
      },
      {
        name: "Actions",
        selector: (row) => (
          <>
            <Camera 
            onClick={() => {

              handleButtonClick(row)
            } }
            style={{ color: "#5e72e4", marginRight: "1rem" }} 
            />
            <DeleteIcon
              style={{ color: "#5e72e4",  cursor: "pointer" }}
              onClick={() => {
                axios
                  .post(baseUrl + "/admin/delete-essential", {
                    type: "MAKER",
                    id: row?._id,
                  })
                  .then((data) => {
                    alert("Deleted successfully");
                    getAllAcounts();
                  })
                  .catch((err) => alert("Something went wrong."));
              }}
            />
          </>
        ),
      },
		],
		[],
	);

  
  const columnsModel = useMemo(
		() => [
      {
        name: "Name",
        selector: (row) => row.name,
      },
      {
        name: "Makers",
        selector: (row) => row.maker?.name || "NA",
      },
      {
        name: "Years",
        selector: (row) => `${row?.years?.de} al ${row?.years?.al}` || "NA",
      },
      {
        name: "Actions",
        selector: (row) => (
          <>
            {/* <EditIcon style={{ color: "#5e72e4", marginRight: "1rem" }} /> */}
            <CalendarMonth
            onClick={() => {
              
              setIsYears(true)
              handleButtonClick(row)
            } }
            style={{ color: "#5e72e4", marginRight: "1rem" }} 
            />
            <EditIcon 
            onClick={() => {

              handleButtonClick(row)
            } }
            style={{ color: "#5e72e4", marginRight: "1rem" }} 
            />
            <DeleteIcon
              style={{ color: "#5e72e4", marginRight: "1rem", cursor: "pointer" }}
              onClick={() => {
                axios
                  .post(baseUrl + "/admin/delete-essential", {
                    type: "MODEL",
                    id: row?._id,
                  })
                  .then((data) => {
                    alert("Deleted successfully");
                    getAllAcounts();
                  })
                  .catch((err) => alert("Something went wrong."));
              }}
            />
          </>
        ),
      },
		],
		[],
	);

  
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col"></div>
                  <div className="col text-right">
                    <Button color="primary" onClick={handleOpen} size="sm">
                      Add
                    </Button>
                    <MyModal
                      open={open}
                      handleClose={handleClose}
                      value={value}
                      makers={data?.data?.data?.makers}
                      categories={data?.data?.data?.categories}
                      type={data?.data?.data?.typeCars}
                      servicesCategories={data?.data?.data?.servicesCategories}
                    />
                    <MyModalEdit
                      open={openEditModal}
                      handleClose={handleCloseEdit}
                      value={value}
                      makers={data?.data?.data?.makers}
                      categories={data?.data?.data?.categories}
                      itemEdit={itemEdit}
                      type={data?.data?.data?.typeCars}
                      isYears={isYears}
                      setIsYears={setIsYears}
                      refetch={refetch}
                    />
                  
                  </div>
                </Row>
              </CardHeader>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                  >
                    <Tab
                      label="Categories"
                      style={{
                        fontWeight: "bold",
                      }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Models"
                      style={{
                        fontWeight: "bold",
                      }}
                      {...a11yProps(1)}
                    />
                    <Tab
                      label="Makers"
                      style={{
                        fontWeight: "bold",
                      }}
                      {...a11yProps(2)}
                    />
                    <Tab
                      label="Sub Categories"
                      style={{
                        fontWeight: "bold",
                      }}
                      {...a11yProps(3)}
                    />
                    <Tab
                      label="Type Cars"
                      style={{
                        fontWeight: "bold",
                      }}
                      {...a11yProps(4)}
                    />
                    <Tab
                      label="Type Services"
                      style={{
                        fontWeight: "bold",
                      }}
                      {...a11yProps(5)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <DataTable
                    responsive
                    columns={categoriesColumns}
                    data={data?.data?.data?.categories}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <DataTable
                    responsive
                    columns={columnsModel}
                    data={data?.data?.data?.models}
                    onSelectedRowsChange={handleChange2}
                   
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <DataTable
                    title="Makers"
                    data={data?.data?.data?.makers}
                    columns={columns}
                    selectableRows
                    onSelectedRowsChange={handleChange2}
                    responsive
                  />
                 
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <DataTable
                    responsive
                    columns={subCategoriesColumns}
                    data={data?.data?.data?.subcategories}
                  />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <DataTable
                    responsive
                    columns={typesCarColumns}
                    data={data?.data?.data?.typeCars}
                  />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <DataTable
                    responsive
                    columns={typesServicesColumns}
                    data={data?.data?.data?.services}
                  />
                </TabPanel>
               
              </Box>
            </Card>
          </Col>
        </Row>
       
        
      </Container>
    </>
  );
};

export default Essentials;

const categoriesColumns = [
  {
    name: "Name",
    selector: (row) => row.name,
  },
  {
    name: "Disabled",
    selector: (row) => row.disabled.toString(),
  },
  {
    name: "Actions",
    selector: (row) => (
      <>
        {/* <EditIcon style={{ color: "#5e72e4", marginRight: "1rem" }} /> */}
        <DeleteIcon
          style={{ color: "#5e72e4", marginRight: "1rem", cursor: "pointer" }}
          onClick={() => {
            axios
              .post(baseUrl + "/admin/delete-essential", {
                type: "CATEGORY",
                id: row?._id,
              })
              .then((data) => {
                alert("Deleted successfully");
                getAllAcounts();
              })
              .catch((err) => alert("Something went wrong."));
          }}
        />
      </>
    ),
  },
];

const typesCarColumns = [
  {
    name: "Tipo",
    selector: (row) => row.type,
  },
  
  {
    name: "Actions",
    selector: (row) => (
      <>
        <DeleteIcon
          style={{ color: "#5e72e4", marginRight: "1rem", cursor: "pointer" }}
          onClick={() => {
            axios
              .post(baseUrl + "/admin/delete-essential", {
                type: "TYPECAR",
                id: row?._id,
              })
              .then((data) => {
                alert("Deleted successfully");
                getAllAcounts();
              })
              .catch((err) => alert("Something went wrong."));
          }}
        />
      </>
    ),
  },
];
const typesServicesColumns = [
  {
    name: "Tipo",
    selector: (row) => row.name,
  },
  
  {
    name: "Actions",
    selector: (row) => (
      <>
        <DeleteIcon
          style={{ color: "#5e72e4", marginRight: "1rem", cursor: "pointer" }}
          onClick={() => {
            axios
              .post(baseUrl + "/admin/delete-essential", {
                type: "TYPESERVICES",
                id: row?._id,
              })
              .then((data) => {
                alert("Deleted successfully");
                getAllAcounts();
              })
              .catch((err) => alert("Something went wrong."));
          }}
        />
      </>
    ),
  },
];
const subCategoriesColumns = [
  {
    name: "Name",
    selector: (row) => row.name,
  },
  {
    name: "Main Category",
    selector: (row) => row.mainCategory?.name,
  },
  {
    name: "Disabled",
    selector: (row) => row.disabled.toString(),
  },
  {
    name: "Actions",
    selector: (row) => (
      <>
        <DeleteIcon
          style={{ color: "#5e72e4", marginRight: "1rem", cursor: "pointer" }}
          onClick={() => {
            axios
              .post(baseUrl + "/admin/delete-essential", {
                type: "SUBCATEGORY",
                id: row?._id,
              })
              .then((data) => {
                alert("Deleted successfully");
                getAllAcounts();
              })
              .catch((err) => alert("Something went wrong."));
          }}
        />
      </>
    ),
  },
];



const MyModalEdit = ({ open, handleClose, value, isYears,setIsYears, categories,itemEdit, type,refetch }) => {
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [photo, setPhoto] = useState('')
  const myArr = ["category", "modal", "maker", "sub category","Car Type","Type Services","Update years"];
  const apiUrl = [
    "categories/create-category",
    "modals/update-modal",
    "maker/update-photo-maker",
    "categories/create-subcategory",
    "info-user/create-type-car",
    "services/create-type-service",
    "modals/update-year-modal"
  ];

  function createEssential() {

    const formData = new FormData();
    formData.append('photo', photo); 

    return axios.post(`${baseUrl}/${apiUrl[value]}/${itemEdit._id}`, formData);
  }



  const [image, setImage] = useState(itemEdit.photo ? `${baseUrl}/${itemEdit.photo}` : null);
  const [name, setName] = React.useState("");
  const {
    data,
   
    isLoading,
    remove,
    isFetching,
    error,
    isError,
    isSuccess,
  } = useQuery("createEssentials", createEssential, {
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      alert(`${myArr[value]} created successfully`);
      handleClose(remove, setName);
    },
    onError: () => {
      alert(error?.response?.data?.message);
      handleClose(remove, setName);
    },
  });
  const [modalMaker, setModalMaker] = React.useState(itemEdit._id);
  const [subcategoryMainCategory, setSubcategoryMainCategory] = React.useState("");
  const [typeCars, setTypeCars] = useState("")
  const [valueInputs, setValueInputs] = useState({
    de:null,
    al:null
  })
  const handleChange = (event) => {
    setModalMaker(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSubcategoryMainCategory(event.target.value);
  };
  const  handleImageUpload = (event) => {
    const file = event.target.files[0]
    const url = URL.createObjectURL(file);
   
    setImage(url);
    setPhoto(file)
  }

  const updatePhoto = async() => {
    try {
      const formData = new FormData();
      if (!photo) {
        Swal.fire('Cambia la foto')
        return
      }
      formData.append('photo', photo); 
      const apiCall = await axios.post(`${baseUrl}/${apiUrl[value]}/${itemEdit._id}`, formData);

      setImage(null)
      Swal.fire('Actualizado correctamente')
      handleClose()
      
    } catch (error) {
      setImage(null)
      handleClose()
      
    }
  } 

  const onChange = async() => {
  
    if (value===2) {
      updatePhoto()
    }
    try {
      let data = typeCars;
      let url = apiUrl[value]
      if (isYears) {
        data = valueInputs;
        url = apiUrl[6]
      }

    
      setIsYears(false)
      const apiCall = await axios.put(`${baseUrl}/${url}/${itemEdit._id}`,{typeCars:data});

      refetch()
      Swal.fire('Actualizado correctamente')
      handleClose()
    } catch (error) {
      
      Swal.fire('error')
      handleClose()
    }
  }

 
  return (
    <Modal
      open={open}
      onClose={() => handleClose(remove, setName)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          style={{ marginBottom: "15px" }}
          variant="h6"
          component="h2"
        >
          {`Edit photo ${myArr[value]}`}
        </Typography>
        {isFetching && <p>Loading...</p>}

        <Typography>{itemEdit.name}</Typography>
        {
          value === 2 && (
            <FormControl fullWidth>

            {image && <Avatar 
            src={image} 
            />}
         

              <IconButton 
            color="primary" 
            aria-label="upload picture" 
            component="label"
            >
              <input 
              hidden 
              accept="image/*" 
              type="file" 
              onChange={handleImageUpload}
              />
              <PhotoCamera />
            </IconButton>
          </FormControl>
          )
        }
        <br />
        <br />
        {value === 1 && !isYears && (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={typeCars}
              label="Tipo de vehiculo"
              onChange={({target}) => {
                setTypeCars(target.value)
              }}
            >
              {type &&
                type.map((item) => {
                  return <MenuItem key={item._id}  value={item._id}>{item.type}</MenuItem>;
                })}
            </Select>
          </FormControl>
        )}
        {value === 3 && (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label2">
              Main Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-label2"
              id="demo-simple-select2"
              value={subcategoryMainCategory}
              label="Main Category"
              onChange={handleCategoryChange}
            >
              {categories &&
                categories.map((item) => {
                  return <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>;
                })}
            </Select>
          </FormControl>
        )}
        {value === 4 && (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label3">
              Tipo de Carros
            </InputLabel>
           
          </FormControl>
        )}
        {value === 5 && (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label4">
              Usar solo numeros
            </InputLabel>
           
          </FormControl>
        )}
        {value === 1 && isYears && (
          <>
          <FormControl fullWidth>
            
            <Input
              type="number"
              inputProps={{
                pattern: '[0-9]*', // Expresión regular para aceptar solo números
              }}
              placeholder="De"
              onChangeCapture={(txt) => setValueInputs({
                ...valueInputs,
                de:txt.target.value
              })}
            />
            <br/>
       
          
          
         
        </FormControl>
        <FormControl fullWidth>
            
         
              <Input
                type="number"
                inputProps={{
                  pattern: '[0-9]*', // Expresión regular para aceptar solo números
                }}
                placeholder="Al"
                onChangeCapture={(txt) => setValueInputs({
                  ...valueInputs,
                  al:txt.target.value
                })}
              />
           
          </FormControl>
          </>
          
          
        )}
        <br />
        <Button
          color="primary"
          style={{ float: "right", marginTop: "15px" }}
          size="sm"
          onClick={onChange}
        >
          Actualizar
        </Button>
      </Box>
    </Modal>
  );
};

const MyModal = ({ open, handleClose, value, makers, categories,type,servicesCategories }) => {
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [image, setImage] = useState(null)
  const [photo, setPhoto] = useState('')
  const myArr = ["category", "modal", "maker", "sub category","Car Type","Type Services","Services Categories"];
  const apiUrl = [
    "/categories/create-category",
    "/modals/create-modal",
    "/maker/create-Maker",
    "/categories/create-subcategory",
    "/info-user/create-type-car",
    "/services/create-type-service",
    "/create-categories-services"
  ];

  function createEssential() {
   
    
    if (value === 2) {
      const formData = new FormData();
      if (!photo) {
        Swal.fire('Cambia la foto')
        return
      }
      formData.append('photo', photo);
      const jsonBody = {
        name,
        makerId: modalMaker,
        mainCategoryId: subcategoryMainCategory,
  
      };
      // formData.append('jsonBody', JSON.stringify(jsonBody));
      const resp = axios.post(baseUrl + "" + apiUrl[value], formData);
      return resp
    } else{
      return axios.post(baseUrl + "" + apiUrl[value], {
        name,
        makerId: modalMaker,
        mainCategoryId: subcategoryMainCategory,
      });
    } 
   
   
   
  }

  const [name, setName] = React.useState("");
 
  const {
    data,
    refetch,
    isLoading,
    remove,
    isFetching,
    error,
    isError,
    isSuccess,
  } = useQuery("createEssentials", createEssential, {
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      alert(`${myArr[value]} created successfully`);
      handleClose(remove, setName);
    },
    onError: () => {
      alert(error?.response?.data?.message);
      handleClose(remove, setName);
    },
  });
  const [modalMaker, setModalMaker] = React.useState("");
  const [subcategoryMainCategory, setSubcategoryMainCategory] =React.useState("");
  const [typeCars, setTypeCars] = useState("");
  const [valueInputs, setValueInputs] = useState({
    type:'',
    km:'',
    period:'',
    category:''

  })
  
  const fetchRequest = async() => {
    try {
      let apiCall;
      const url = `${baseUrl}${apiUrl[value]}`


      if (!photo && value == 2) {
        Swal.fire(`Falta el logo`)
        return
      }
      if (value === 5) {
        apiCall = await axios.post(url, {
          name,
          ...valueInputs
        });
      }else{
       
        apiCall = await axios.post(url, {
          name,
          makerId: modalMaker,
          mainCategoryId: subcategoryMainCategory,
          typeCar:typeCars
        });
      }
    
     

      if (value == 2) {
        updatePhoto(apiCall?.data?.data)
      }
      Swal.fire(`${myArr[value]} created successfully`)
      handleClose(remove, setName);
      
    } catch (error) {
     
      alert(error?.response?.data?.message);
      handleClose(remove, setName);
      
    }
  } 

  const handleChange = (event) => {
    setModalMaker(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSubcategoryMainCategory(event.target.value);
  };

  const  handleImageUpload = (event) => {
    const file = event.target.files[0]
    const url = URL.createObjectURL(file);
   
    setImage(url);
    setPhoto(file)
  }

  const updatePhoto = async(item) => {
    try {
     
      
      if (!photo) {
        
        return
      }
      const formData = new FormData();
    
      formData.append('photo', photo); 
      const apiCall = await axios.post(`${baseUrl}/maker/update-photo-maker/${item._id}`, formData);

      setImage(null)
      Swal.fire('Actualizado correctamente')
      handleClose()
      
    } catch (error) {
      Swal.fire('error al guardar logo')
      setImage(null)
      handleClose()
      
    }
  } 

  const onChangeValue = (name,inp) => {
    setValueInputs({
      ...valueInputs,
      [name]:inp
    })
  }


  return (
    <Modal
      open={open}
      onClose={() => handleClose(remove, setName)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          style={{ marginBottom: "15px" }}
          variant="h6"
          component="h2"
        >
          {`Create new ${myArr[value]}`}
        </Typography>
        {isFetching && <p>Loading...</p>}
        <TextField
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          id="outlined-basic"
          label="Enter name"
          variant="outlined"
        />
       
         {image && <Avatar 
            src={image} 
            />}
         {
          value == 2 &&  (

           
         
           
              <IconButton 
            color="primary" 
            aria-label="upload picture" 
            component="label"
            >
              <Typography>Logo</Typography>
              <input 
              hidden 
              accept="image/*" 
              type="file" 
              onChange={handleImageUpload}
              />
              <PhotoCamera />
            </IconButton>
            )
         }
        <br />
        <br />
        {value === 1 && (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Maker</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={modalMaker}
              label="Maker"
              onChange={handleChange}
            >
              {makers &&
                makers.map((item) => {
                  return <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>;
                })}
            </Select>
            <br/>
            <InputLabel id="demo-simple-select-label">Tipos</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={typeCars}
              label="Tipo de vehiculo"
              onChange={({target}) => {
                setTypeCars(target.value)
              }}
            >
              {type &&
                type.map((item) => {
                  return <MenuItem key={item._id} value={item._id}>{item.type}</MenuItem>;
                })}
            </Select>
          </FormControl>
        )}
        {value === 3 && (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label2">
              Main Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-label2"
              id="demo-simple-select2"
              value={subcategoryMainCategory}
              label="Main Category"
              onChange={handleCategoryChange}
            >
              {categories &&
                categories.map((item) => {
                  return <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>;
                })}
            </Select>
          </FormControl>
        )}
        {value === 4 && (
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label3">
              Tipo de Carros
            </InputLabel> */}
           
          </FormControl>
        )}
        {value === 5 && (
          <>
           <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label4">
              Tipo
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={valueInputs?.age}
            
              onChange={(event) => { onChangeValue('type',event.target.value) }}
            >
              <MenuItem value={'Estetica'}>Estetica</MenuItem>
              <MenuItem value={'Preventivo'}>Preventivo</MenuItem>
              <MenuItem value={'Correctivo'}>Correctivo</MenuItem>
            </Select>
           
          </FormControl>
          
          <FormControl fullWidth style={{ marginTop: '16px' }}>
            <InputLabel id="categoria">
              Categoria
            </InputLabel>

            <Select
            
              id="categoria-select"
              value={valueInputs?.age}
              label="Categoria"
              onChange={(event) => { onChangeValue('category',event.target.value) }}
            >
             
              {
                servicesCategories.map((item) =>  <MenuItem value={item._id}>{item.name}</MenuItem> )
              }
            </Select>
           
          </FormControl>

          <FormControl>
          <TextField
            id="km"
            label="Kilómetros"
            type="number"
            value={valueInputs.km}
            onChange={(event) => onChangeValue('km',event.target.value)}
            fullWidth
            margin="normal"
          />
          </FormControl>
          

            <FormControl>
            <TextField
            id="period"
            label="Periodo (meses)"
            type="number"
            value={valueInputs.period}
            onChange={(event) => onChangeValue('period',event.target.value)}
            fullWidth
            margin="normal"
          />
            </FormControl>
         
          </>
         

          
        )}
         {value === 6 && (
          <>
           <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label4">
              Nombre
            </InputLabel>

            <TextField
            id="period"
            label="Periodo (meses)"
            
            value={valueInputs.name}
            onChange={(event) => onChangeValue('name',event.target.value)}
            fullWidth
            margin="normal"
          />
           
          </FormControl>
          
          <FormControl fullWidth style={{ marginTop: '16px' }}>
            <InputLabel id="categoria">
              Imagen
            </InputLabel>

           
           
          </FormControl>

        
          

        
           
           
         
          </>
         

          
        )}
        <br />
        <Button
          color="primary"
          style={{ float: "right", marginTop: "15px" }}
          size="sm"
          onClick={fetchRequest}
        >
          Add New
        </Button>
      </Box>
    </Modal>
  );
};





