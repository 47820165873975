import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Button, Checkbox, Divider, TableHead } from '@mui/material';
import { ListItem } from './ListItem';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import axios from 'axios';
import { baseUrl } from 'config';
import { Add } from '@mui/icons-material';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
  
  function createData(name, calories, fat) {
    return { name, calories, fat };
  }
  
  

export const TableMatch = ({matchs,setIsView,setSelectItem}) => {

   
  
  
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - matchs.length) : 0;
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    


    // console.log(matchs);
    
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Modelo</TableCell>
                            <TableCell align="justify">Subcategoria</TableCell>
                            <TableCell align="justify">Aplicacion</TableCell>
                            <TableCell align="justify">Compatible</TableCell>
                            <TableCell padding="checkbox">
                                Edit
                            </TableCell>
                        </TableRow>
                    </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? matchs.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                          )
                        : matchs
                    ).map((match) => (
                        <TableRow key={match._id}>
                            <TableCell component="th" scope="row">
                                <p>
                                    {match?.model?.name}
                                </p>
                                
                            </TableCell>
                            <TableCell align="justify">
                                <p>
                                {match?.subCategoryId?.name}
                                </p>
                                
                            </TableCell>
                            <TableCell align="justify">
                                {match.de && (
                                    <p>
                                    {match?.de} al {match?.al}
                                    </p>
                                )}
                            </TableCell>
                            <TableCell align="justify">
                                {match?.matches && (
                                   <ListItem data={match?.matches} />
                                )}
                            </TableCell>
                            <TableCell padding="checkbox"  >


                              <IconButton 
                                color="primary" 
                                aria-label="edit" 
                                component="label"
                                onClick={() => {
                                  setIsView('newAdd')
                                  setSelectItem(match)
                                }}
                                >
                                
                                    <Add />
                                </IconButton>
                                    
                                <IconButton 
                                color="primary" 
                                aria-label="edit" 
                                component="label"
                                onClick={() => {
                                  setIsView('edit')
                                  setSelectItem(match)
                                }}
                                >
                                
                                    <Edit />
                                </IconButton>
                            
                            
                                <IconButton 
                                 onClick={() => {
                                  setIsView('delete')
                                  setSelectItem(match)
                                }}
                                color="error" aria-label="delete" component="label">
                                
                                    <Delete />
                                </IconButton>
                            </TableCell>
                            
                        </TableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                { label: "All", value: -1 },
                            ]}
                            colSpan={3}
                            count={matchs.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    "aria-label": "rows per page",
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        
    );
}
