import { Delete } from '@mui/icons-material';
import { Divider, IconButton, List, ListItem, ListItemText } from '@mui/material';

import axios from 'axios';
import Header from 'components/Headers/Header'
import { TableBlackList } from 'components/TableBlackList'
import { baseUrl } from 'config';

import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { Container } from 'reactstrap'
import Swal from 'sweetalert2';


function getAllBlockZone() {
  return axios.get(baseUrl + "/admin/all-zone-block");
}


function deleteBlockZone(id) {
  return axios.delete(baseUrl + "/admin/delete-zone-block/" + id);
}


export const BlackList = () => {
  const { data } = useQuery("get-all-zone-block", getAllBlockZone);

  const [zoneBlock, setZoneBlock] = useState(false);


  const releoadingZone  = async() => {
    const zone = await getAllBlockZone();
      
    setZoneBlock(zone.data.data)
  }

  const deleteZone = async(item) => {
   
    try {
      const {data} = await deleteBlockZone(item._id);

      
      Swal.fire({
        title: "Success!",
        text: data.message,
        icon: "success",
        confirmButtonText: "Aceptar",
      });
      releoadingZone()
     
    } catch (error) {
      console.log("🚀 ~ file: BlackList.js:34 ~ deleteZone ~ error:", error)
      
    }
   
  }

  useEffect(() => {
    
    const getZone = () => {
      setZoneBlock(data?.data?.data)
    }
    
    getZone()
  }, [data])
  
 
  return (
    <>
      <Header />
      <Container className="mt-4" fluid>

        <TableBlackList releoadingZone={releoadingZone} />
        <div style={{ marginTop:20, width: '100%' }}>
          {
            zoneBlock && renderZone(zoneBlock,deleteZone)
          }
          
        </div>
        
      </Container>
    </>
  )
}


const renderZone = (item,deleteZone) => {

  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper',borderWidth:10 }}>
    {item.map((value) => (
      <div
      key={value._id}
      >      
      <ListItem
        
        disableGutters
        secondaryAction={
          <IconButton 
          onClick={()=> deleteZone(value)}
          aria-label="comment">
            <Delete color='red' />
          </IconButton>
        }
      >
        <ListItemText primary={`${value.formatted_address}`} />
        
      </ListItem>
      <Divider/>
      </div>

    ))}
   
  </List>
  )
}
