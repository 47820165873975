import { Button, CardActions, CardContent, TextField, Typography } from '@mui/material'
import axios from 'axios';
import { baseUrl } from 'config';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import React, { useEffect, useState } from 'react'
import { Card } from 'reactstrap';
import Swal from 'sweetalert2';





export const BlackListForm = ({releoadingZone}) => {
  const [viewAddress, setViewAddress] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [term, setTerm] = useState('')
  const [textValue, setTextValue] = useState('')
  const deboncedValue = useDebouncedValue(textValue);


  const selectCol = async (item) => {
    try {

      let loc = {
        latitude: item?.geometry?.location.lat,
        longitude: item?.geometry?.location.lng,
      }

      const { data } = await axios.post(`${baseUrl}/admin/search-zone`, loc);


      setViewAddress(data.data);
      setAddresses([]);

    } catch (error) {
      console.log(error,'selectCol');
      
    }
  }

  const blockZone = async (zone) => {
    try {
      const { data } = await axios.post(`${baseUrl}/admin/block-zone`, zone);
      
      if (data.success) {
        Swal.fire({
          title: "Success!",
          text: data.message,
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        releoadingZone()

      }else{
        Swal.fire({
          title: "Error!",
          text: data.message,
          icon: "error",
          confirmButtonText: "Aceptar",
      });
      }

      setViewAddress([])
      
    } catch (error) {
     
      Swal.fire({
        title: "Error!",
        text: 'No hay conexion con el servidor.',
        icon: "error",
        confirmButtonText: "Aceptar",
    });
      setViewAddress([])
    }
  }

  const handleChange = (txt) => {


    setTerm(txt)

  }

  const autocompleteAddress = async () => {

    try {
      const { data } = await axios.get(`${baseUrl}/addresses/search-address/${term}`);

      let d = data.data.map(elem => {
        return {
          ...elem,
          label: elem.formatted_address
        }
      });


      setAddresses(d)
    } catch (error) {

    }

  }

  useEffect(() => {
    if (term.length === 0) {
      return setAddresses([]);
    }
    autocompleteAddress()
  }, [term])


  const debounce = async (e) => {

    setTextValue(e.target.value)

  }

  useEffect(() => {
    handleChange(deboncedValue);
  }, [deboncedValue])


  console.log(viewAddress);

  return (
    <>

      <TextField label="Buscar colonia" onChange={debounce} />

      {
        addresses.map((item) => (
          <p key={item.place_id} >
            {item.label}
            <Button variant="outlined" onClick={() => selectCol(item)} >Seleccionar</Button>
          </p>
        ))
      }


      {
        viewAddress && viewAddress.map((elem) => (
          (
            <Card key={elem.place_id} sx={{ minWidth: 275 }}>
              <CardContent>

                <Typography variant="h5" >
                  {elem.formatted_address}
                </Typography>


              </CardContent>

              <CardActions>
                <Button onClick={() => blockZone(elem)} size="small">Bloquear</Button>
              </CardActions>
            </Card>
          )
        ))
      }


    </>
  )
}
