import { useState, useEffect } from "react";
import axios from "axios";
import {
 
  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
import DataTable from "react-data-table-component";
import Header from "components/Headers/Header.js";
import { baseUrl } from "config";

export const storeColumns = [
  {
    name: "Name",
    selector: (row) => row.storeName,
  },
  {
    name: "Email",
    selector: (row) => row.email,
  },
  {
    name: "State",
    selector: (row) => row.state,
  },
  {
    name: "City",
    selector: (row) => row.city,
  },
  {
    name: "Country",
    selector: (row) => row.country,
  },
  {
    name: "Status",
    selector: (row) => row.status,
  },
  {
    name: "Address",
    selector: (row) => row.address,
  },
];

const Index = (props) => {
  const [stores, setStores] = useState([]);
  useEffect(() => {
    axios
      .get(baseUrl + "/admin/get-stores")
      .then((data) => {
        setStores(data.data.data);
      })
      .catch((err) => {
        console.log("Err", err);
      });
  }, []);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">{"Stores"}</h3>
                  </div>
                  <div className="col text-right">
                    {/* <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Add
                    </Button> */}
                  </div>
                </Row>
              </CardHeader>
              <DataTable
                columns={storeColumns}
                data={stores}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;




