import axios from 'axios';
import { baseUrl } from 'config';
import React from 'react'
import { BlackListForm } from './Form/BlackListForm';







export const TableBlackList = ({releoadingZone}) => {
  return (
    <div>
      
      <BlackListForm releoadingZone={releoadingZone}/>
    </div>
  )
}
