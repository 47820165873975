import Index from "views/Index.js";
import Users from "views/examples/Profile";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Essentials from "views/examples/Tables";
import Orders from "views/examples/Orders";
import Products from "views/examples/Products";
import Fees from "views/examples/Fees";
import Match from "views/examples/Match";
import { BlackList } from "views/examples/BlackList";
import { PreRegistration } from "views/examples/PreRegistration";
import { ServicesCategories } from "views/examples/ServicesCategories";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-yellow",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/essentials",
    name: "Essentials",
    icon: "ni ni-bullet-list-67 text-red",
    component: Essentials,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Products",
    icon: "fas fa-chart-pie text-red",
    component: Products,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-key-25 text-info",
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/fees",
    name: "Fees",
    icon: "fa fa-balance-scale text-pink",
    component: Fees,
    layout: "/admin",
  },
  {
    path: "/matchs",
    name: "Match",
    icon: "ni ni-bullet-list-67 text-red",
    component: Match,
    layout: "/admin",
  },
  {
    path: "/black-list",
    name: "Black List",
    icon: "ni ni-bullet-list-67 text-red",
    component: BlackList,
    layout: "/admin",
  },
  {
    path: "/solicitudes",
    name: "Solicitudes",
    icon: "ni ni-key-25 text-info",
    component: PreRegistration,
    layout: "/admin",
  },
  {
    path: "/create-service-category",
    name: "Categorias de Servicio",
    icon: "ni ni-key-25 text-info",
    component: ServicesCategories,
    layout: "/admin",
  },
  
];
export default routes;
