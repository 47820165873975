import { Button, Col, Container, Row } from "reactstrap";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import Header from "components/Headers/Header";
import axios from "axios";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DataTable from "react-data-table-component";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { baseUrl } from "config";
import UserHeader from "components/Headers/UserHeader";
import { Card, CardHeader, CardContent, CardMedia } from "@mui/material";

function getAllAcounts() {
  return axios.get(baseUrl + "/admin/get-all-accounts");
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (remove, setName) => {
    setOpen(false);
    remove();
    setName("");
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExpandedComponentStore = ({ data }) => (
  <Card sx={{ maxWidth: 700, margin: "3rem" }}>
    <CardContent>
      <div
        style={{
          padding: "3rem",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">
            Email:
          </p>
          <p>{data.email}</p>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">
            Address:
          </p>
          <p>{data.address}</p>
        </div>
      </div>
    </CardContent>
  </Card>
);

const ExpandedComponentRiders = ({ data }) => (
  <Card sx={{ maxWidth: 700, margin: "3rem" }}>
    <CardMedia
      component="img"
      height="194"
      image={baseUrl + "/" + data.profile}
      alt="Rider's image"
    />
    <CardContent>
      <div
        style={{
          display: "flex",
        }}
      >
        <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">
          Phone :
        </p>
        <p>{data.phone_number}</p>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">City:</p>
        <p>{data.city}</p>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">
          State:
        </p>
        <p>{data.state}</p>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">
          Country:
        </p>
        <p>{data.country}</p>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <p className="h4 mb-0 text-grey d-none d-lg-inline-block mx-4">
          Address:
        </p>
        <p>{data.address}</p>
      </div>
    </CardContent>
  </Card>
);

const Users = () => {
  const { data } = useQuery("get-all-users", getAllAcounts);
  console.log({tiendas:data});
  const [value, setValue] = React.useState(0);
  const [row, setRow] = React.useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (remove, setName) => {
    setOpen(false);
    remove && remove();
    setName && setName("");
  };

  const [openUser, setOpenUser] = React.useState(false);
  const handleOpenUser = () => setOpenUser(true);
  const handleCloseUser = (remove, setName) => {
    setOpenUser(false);
    remove && remove();
    setName && setName("");
  };

  const [openRider, setOpenRider] = React.useState(false);
  const handleOpenRider = () => setOpenRider(true);
  const handleCloseRider = (remove, setName) => {
    setOpenRider(false);
    remove && remove();
    setName && setName("");
  };


  const storeColumns = [
    {
      name: "Name",
      selector: (row) => row.storeName,
    },
    // {
    //   name: "Email",
    //   selector: (row) => row.email,
    // },
    {
      name: "State",
      selector: (row) => row.state,
    },
    {
      name: "City",
      selector: (row) => row.city,
    },
    {
      name: "Country",
      selector: (row) => row.country,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.isBlocked ? (
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                handleOpen();
                setRow(row);
              }}
            >
              Unblock
            </Button>
          ) : (
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                handleOpen();
                setRow(row);
              }}
            >
              {""}
              Block
            </Button>
          )}
        </div>
      ),
    },
  ];
  const userColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.isBlocked ? (
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                handleOpenUser();
                setRow(row);
              }}
            >
              Unblock
            </Button>
          ) : (
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                handleOpenUser();
                setRow(row);
              }}
            >
              {""}
              Block
            </Button>
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          {/* <EditIcon style={{ color: "#5e72e4", marginRight: "1rem" }} /> */}
          <DeleteIcon
            style={{ color: "#5e72e4", marginRight: "1rem", cursor: "pointer" }}
            onClick={() => {
              axios
                .post(baseUrl + "/admin/delete-block-users", {
                  tag: "USER",
                  flag: "DELETE",
                  id: row?._id,
                })
                .then((data) => {
                  alert("Deleted successfully");
                  getAllAcounts();
                })
                .catch((err) => alert("Something went wrong."));
            }}
          />
        </>
      ),
    },
  ];

  const riderColumns = [
    {
      name: "Name",
      selector: (row) => row.riderName,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.isBlocked ? (
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                handleOpenRider();
                setRow(row);
              }}
            >
              Unblock
            </Button>
          ) : (
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                handleOpenRider();
                setRow(row);
              }}
            >
              {""}
              Block
            </Button>
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          {/* <EditIcon style={{ color: "#5e72e4", marginRight: "1rem" }} /> */}
          <DeleteIcon
            style={{ color: "#5e72e4", marginRight: "1rem", cursor: "pointer" }}
            onClick={() => {
              axios
                .post(baseUrl + "/admin/delete-block-users", {
                  tag: "RIDER",
                  flag: "DELETE",
                  id: row?._id,
                })
                .then((data) => {
                  alert("Deleted successfully");
                  getAllAcounts();
                })
                .catch((err) => alert("Something went wrong."));
            }}
          />
        </>
      ),
    },
  ];
  return (
    <>
      <Header />
      <MyModal open={open} handleClose={handleClose} value={value} row={row} />
      <MyModalUser
        open={openUser}
        handleClose={handleCloseUser}
        value={value}
        row={row}
      />
      <MyModalRider
        open={openRider}
        handleClose={handleCloseRider}
        value={value}
        row={row}
      />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col"></div>
                  <div className="col text-right">
                    {/* <Button color="primary" size="sm">
                      Add
                    </Button> */}
                  </div>
                </Row>
              </CardHeader>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                  >
                    <Tab
                      label="Users"
                      style={{
                        fontWeight: "bold",
                      }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Stores"
                      style={{
                        fontWeight: "bold",
                      }}
                      {...a11yProps(1)}
                    />
                    <Tab
                      label="Riders"
                      style={{
                        fontWeight: "bold",
                      }}
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <DataTable
                    responsive
                    columns={userColumns}
                    data={data?.data?.data?.users}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <DataTable
                    responsive
                    columns={storeColumns}
                    data={data?.data?.data?.stores}
                    expandableRows
                    expandableRowsComponent={ExpandedComponentStore}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <DataTable
                    responsive
                    columns={riderColumns}
                    data={data?.data?.data?.riders}
                    expandableRows
                    expandableRowsComponent={ExpandedComponentRiders}
                  />
                </TabPanel>
              </Box>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Users;

const MyModal = ({ open, handleClose, row }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  function changeStatus() {
    if (row.isBlocked) {
      return axios.post(baseUrl + "/admin/unblock-store", {
        storeId: row._id,
        reason: reason,
      });
    } else {
      return axios.post(baseUrl + "/admin/block-store", {
        storeId: row._id,
        reason: reason,
      });
    }
  }

  const [reason, setReason] = React.useState("");
  const {
    data,
    refetch,
    isLoading,
    remove,
    isFetching,
    error,
    isError,
    isSuccess,
  } = useQuery("changeStatusStore", changeStatus, {
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      alert("Status changes successfully");
      handleClose(remove, setReason);
    },
    onError: () => {
      alert(error?.response?.data?.message);
      handleClose(remove, setReason);
    },
  });
  // console.log("Status", isFetching, error, isSuccess, isError, data);
  return (
    <Modal
      open={open}
      onClose={() => handleClose(remove, setReason)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          style={{ marginBottom: "15px" }}
          variant="h6"
          component="h2"
        >
          {row?.isBlocked ? "Ublock" : "Block"}
        </Typography>
        {isFetching && <p>Loading...</p>}
        <TextField
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          id="outlined-basic"
          label="Enter reason"
          variant="outlined"
        />
        <br />
        <Button
          color="primary"
          style={{ float: "right", marginTop: "15px" }}
          size="sm"
          onClick={() => refetch()}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

const MyModalUser = ({ open, handleClose, row }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [reason, setReason] = React.useState("");

  function changeStatus() {
    if (row.isBlocked) {
      return axios.post(baseUrl + "/admin/delete-block-users", {
        tag: "USER",
        flag: "BLOCK",
        id: row?._id,
        reason
      });
    } else {
      return axios.post(baseUrl + "/admin/delete-block-users", {
        tag: "USER",
        flag: "UNBLOCK",
        id: row?._id,
        reason
      });
    }
  }

  const {
    data,
    refetch,
    isLoading,
    remove,
    isFetching,
    error,
    isError,
    isSuccess,
  } = useQuery("changeStatusUser", changeStatus, {
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      alert("Status changes successfully");
      handleClose(remove, setReason);
    },
    onError: () => {
      alert(error?.response?.data?.message);
      handleClose(remove, setReason);
    },
  });
  // console.log("Status", isFetching, error, isSuccess, isError, data);
  return (
    <Modal
      open={open}
      onClose={() => handleClose(remove, setReason)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          style={{ marginBottom: "15px" }}
          variant="h6"
          component="h2"
        >
          {row?.isBlocked ? "Ublock" : "Block"}
        </Typography>
        {isFetching && <p>Loading...</p>}
        <TextField
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          id="outlined-basic"
          label="Enter reason"
          variant="outlined"
        />
        <br />
        <Button
          color="primary"
          style={{ float: "right", marginTop: "15px" }}
          size="sm"
          onClick={() => refetch()}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

const MyModalRider = ({ open, handleClose, row }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [reason, setReason] = React.useState("");

  function changeStatus() {
    if (row.isBlocked) {
      return axios.post(baseUrl + "/admin/delete-block-users", {
        tag: "RIDER",
        flag: "BLOCK",
        id: row?._id,
        reason
      });
    } else {
      return axios.post(baseUrl + "/admin/delete-block-users", {
        tag: "RIDER",
        flag: "UNBLOCK",
        id: row?._id,
        reason,
      });
    }
  }

  const {
    data,
    refetch,
    isLoading,
    remove,
    isFetching,
    error,
    isError,
    isSuccess,
  } = useQuery("changeStatusRider", changeStatus, {
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      alert("Status changes successfully");
      handleClose(remove, setReason);
    },
    onError: () => {
      alert(error?.response?.data?.message);
      handleClose(remove, setReason);
    },
  });
  // console.log("Status", isFetching, error, isSuccess, isError, data);
  return (
    <Modal
      open={open}
      onClose={() => handleClose(remove, setReason)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          style={{ marginBottom: "15px" }}
          variant="h6"
          component="h2"
        >
          {row?.isBlocked ? "Ublock" : "Block"}
        </Typography>
        {isFetching && <p>Loading...</p>}
        <TextField
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          id="outlined-basic"
          label="Enter reason"
          variant="outlined"
        />
        <br />
        <Button
          color="primary"
          style={{ float: "right", marginTop: "15px" }}
          size="sm"
          onClick={() => refetch()}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};
