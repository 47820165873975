import React, {  useState } from "react";
import Box from "@mui/material/Box";

import { Col, Row } from "reactstrap";
import { Divider,  Typography } from "@mui/material";
import axios from "axios";
import { baseUrl } from "config";
import Swal from "sweetalert2";
import { CardCar } from "components/ui/CardCar";

const style = {
    // margin: 10,
    marginTop:10,
    marginBottom:10
};

export const DeleteMatch = (props) => {
    
    const {getMatchs,selectItem} = props;
   

   

    const [newMatches, setNewMatches] = useState(selectItem?.matches)


   
   




    
    const carDelete = async(car) => {
        
        try {
            
            let result = await axios.delete( `${baseUrl}/match/delete-match/${car._id}`,{
                data:{
                    carDelete:car,
                    match:selectItem
                }
            });
           
            
            if (result.status === 200) {
                await getMatchs()
                    Swal.fire({
                        title: "Success!",
                        text: 'Actualizado correctamente',
                        icon: "success",
                        confirmButtonText: "Aceptar",
                        
                    });
            }
            
          } catch (error) {
            Swal.fire({
                title: "Error!",
                text: error?.message,
                icon: "error",
                confirmButtonText: "Aceptar",
            });
          }
    }

    
    return (
        <>
            <Typography variant="h3"  >Eliminar Matchs</Typography>
            <Divider />
            <Row className="mt-5 ">
                <Col className="mb-5 mb-xl-0">
                    <CardCar car={selectItem} carDelete={carDelete} />
                </Col>
                <Col className="mb-5 mb-xl-0">

                  
                </Col>
                <Col className="mb-5 mb-xl-0">
                    {
                        newMatches.map((m,i) => {
                            
                           
                            return (
                                <div key={i}>
                                    <Box  style={style}>
                                        <CardCar car={m} carDelete={carDelete} />
                                    </Box>
                                </div>
                            );
                            
                        })
                    }
                      
                        
                </Col>
            </Row>

         
        </>
    );
};



