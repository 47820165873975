import { Delete } from '@mui/icons-material';
import { Card, CardHeader, IconButton } from '@mui/material'
import {  red } from '@mui/material/colors';
import React from 'react'

export const CardCar = ({car,carDelete}) => {
  // console.log(car);
  return (
    <Card sx={{ maxWidth: 345 }}>
    <CardHeader
        action={
        <IconButton aria-label="delete" onClick={() => carDelete(car)}  >
            <Delete sx={{ color: red[500] }} />
        </IconButton>
        }
        title={car?.model?.name}
        subheader={`Del ${car?.de} al ${car?.al}`}
    />


    </Card>
  )
}
