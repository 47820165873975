import {  Col, Container, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Header from "components/Headers/Header";
import axios from "axios";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { baseUrl } from "config";
import { Card, Button } from "@mui/material";
import { FormMatch } from "components/Form/FormMatch";
import { TableMatch } from "components/TableMatch";
import { EditMatch } from "components/Form/EditMatch";
import { DeleteMatch } from "components/Form/DeleteMatch";
import Swal from "sweetalert2";
import { NewAdd } from "components/Form/NewAdd";

function getAllAcounts() {
  return axios.get(baseUrl + "/admin/get-all-essentials");
}





const Match = () => {
    const { data } = useQuery("get-all-users", getAllAcounts);
   
    const [matchs, setMatchs] = useState(false)
    const [isView, setIsView] = useState('table');
    const [selectItem, setSelectItem] = useState(false)
    
    const getMatchs = async( ) => {
      try {
        let result = await axios.get(baseUrl + "/match/get-matchs");
     
        if (result.status == 200) {
          setMatchs(result?.data?.data)
          setIsView('table')
          
        }
        
      } catch (error) {
        console.log(error,'getMatchs');
      }
     

    }

    useEffect(() => {
      getMatchs();
    }, [])
    

    
    const allMatch = async() => {
      try {
        let result = await axios.post(baseUrl + "/match/all-match");
     
        if (result.status == 200) {
          Swal.fire({
            title: "Success!",
            text: 'Actualizado correctamente',
            icon: "success",
            confirmButtonText: "Aceptar",
            
        });
        }
        
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: 'Error al Actualizar',
          icon: "error",
          confirmButtonText: "Aceptar",
      });
      }
    }

    


    return (
        <>
        <Header />
        
        <Container className="mt--7" fluid>
            <Row>
              <Col>
                <Button onClick={allMatch} variant="contained" color="success">
                  Aplicar Matchs a todos
                </Button>      
              </Col>
            </Row>
            <Row className="mt-5">
              
              <Col className="mb-5 mb-xl-0"
            
              >
              
                <div className="col text-right">
                  
                  {
                    (isView === 'add' || isView === 'edit' || isView === 'delete' ) &&  (
                      <div className="col text-right">
                        <Button onClick={()=>setIsView('table') } variant="contained" color="success">
                            All
                        </Button>   
                      
                      </div>
                    )
                  }  
                  {
                    isView === 'table' && (
                      <div className="col text-right">
                        <Button onClick={()=>setIsView('add') } variant="contained" color="success">
                            Add
                        </Button>    
                      </div>
                    )
                  }
                    
                </div>
                
                <Card className="shadow">
                 
                  {
                    isView === 'table' && (
                      <Box>
                          {
                            matchs && (
                              <TableMatch 
                              matchs={matchs} 
                              setIsView={setIsView} 
                              setSelectItem={setSelectItem} 
                              />
                            )
                          }
                    
                      </Box>
                    )
                  }

                  {
                    isView === 'add' && (
                      <Box sx={{ width: "100%" }}>
                          {
                              data && (
                                  <FormMatch  
                                  allEssentials={data?.data?.data} 
                                  edit={false} 
                                  getMatchs={getMatchs} 
                                  />
                              )
                          }
                          
                          
                      </Box>
                    )
                  }

{
                    isView === 'newAdd' && (
                      <Box sx={{ width: "100%" }}>
                          {
                              data && (
                                  <NewAdd  
                                  allEssentials={data?.data?.data} 
                                  edit={false}  
                                  selectItem={selectItem} 
                                  getMatchs={getMatchs} 
                                  />
                              )
                          }
                          
                          
                      </Box>
                    )
                  }

                  {
                    isView === 'edit' && (
                      <Box sx={{ width: "100%" }}>
                          {
                              data && (
                                 <>
                                  <EditMatch  
                                  allEssentials={data?.data?.data} 
                                  edit={true} 
                                  selectItem={selectItem} 
                                  getMatchs={getMatchs} 
                                  />
                                  {
                                    selectItem.matches.map((el,index) => <EditMatch  key={index} allEssentials={data?.data?.data} edit={true} selectItem={el} getMatchs={getMatchs} /> )
                                  }
                                 </>
                              )
                          }
                          
                          
                      </Box>
                    )
                  }

                  {
                    isView === 'delete' && (
                      <Box sx={{ width: "100%" }}>
                          {
                              data && (
                                  <DeleteMatch  allEssentials={data?.data?.data} edit={true} selectItem={selectItem} getMatchs={getMatchs} />
                              )
                          }
                          
                          
                      </Box>
                    )
                  }
                  
                  
                </Card>
              </Col>
              
            </Row>

            
        </Container>
        </>
    );
};

export default Match;






