import {  Grid,  } from '@mui/material'
import React from 'react'

export const ListItem = ({data}) => {
   
  return (
    <>
        
         {
            data.map( (m) => (
                <Grid key={m._id} container spacing={2}>
                <p>
                {m?.model?.name } {' '}
                {m?.de && (
                    <>
                      -{' '}{ m?.de} al {m?.al} 
                    </>
                ) }
                </p>
                
                </Grid>
                
                
            ))
        }
       
            
        
       
    </>
    // <List component="nav" aria-label="mailbox folders">
    //      <Divider />
            
       
        
        
    //     <Divider />
     
    // </List>
  )
}
