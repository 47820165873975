import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";


import { QueryClientProvider, QueryClient } from "react-query";
import App from "./App.js"
const client = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={client}>
    <HashRouter>
     <App />
    </HashRouter>
  </QueryClientProvider>,
  document.getElementById("root")
);
