import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Col, Row } from "reactstrap";
import { Button, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { baseUrl } from "config";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Swal from "sweetalert2";
import moment from "moment";

const style = {
    // margin: 10,
    marginTop:10,
    marginBottom:10
};

export const EditMatch = (props) => {
    const { subcategories, models } = props?.allEssentials;
    
    const {getMatchs,selectItem} = props;
   
    const [valueSub, setValueSub] = useState( selectItem?.subCategoryId?._id );
    const [valueModel, setValueModel] = useState( selectItem?.model?._id);
    const [isSending, setIsSending] = useState(false)

    const [aplicacion, setAplicacion] = useState({
        de:moment(`${selectItem?.de}-04-07`),
        al:moment(`${selectItem?.al}-04-07`),
    });
    const [newMatches, setNewMatches] = useState(selectItem?.matches)

    const handleChangeModel = (event) => {
        setValueModel(event.target.value);
    };
    const handleChangeSub = (event) => {
        setValueSub(event.target.value);
    };
   
  

    const guardarMatch = async () => {
        
        // const matches = newMatches.map((m) => {
        //     return{
        //         ...m,
        //         model:m?.model?._id,
        //         subCategoryId:m?.subCategoryId?._id
        //     }

        // })
       
      setIsSending(true)
        
            try {
                const result = await axios.put(
                    baseUrl + "/match/edit-match",
                    {
                        _id:selectItem?._id,
                        subCategoryId: valueSub,
                        model: valueModel,
                        de: parseInt(aplicacion?.de?.format('YYYY')) ,
                        al: parseInt(aplicacion?.al.format('YYYY')),
                        // matches
                    }
                );
               
                if (result?.data.success) {
                    await getMatchs()
                    Swal.fire({
                        title: "Success!",
                        text: 'Actualizado correctamente',
                        icon: "success",
                        confirmButtonText: "Aceptar",
                        
                    });
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: 'Error al Actualizar',
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    });
                }
                setIsSending(false)
            } catch (error) {
                setIsSending(false)
                Swal.fire({
                    title: "Error!",
                    text: error?.message,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
       
    };

  

    return (
        <>
            <Row className="mt-5 ">
                <Col className="mb-5 mb-xl-0">
                    <Box style={style}>
                        <FormControl fullWidth style={style}>
                            <InputLabel id="demo-simple-select-label">
                                Subcategories
                            </InputLabel>
                            <Select
                                defaultValue={selectItem?.subCategoryId?.name}
                               
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={valueSub}
                                label="Subcategories"
                                onChange={handleChangeSub}
                               
                                >
                                {subcategories.map((item) => {
                                    return (
                                        <MenuItem key={item?._id} value={item?._id}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
   
                        </FormControl>
                        <FormControl fullWidth style={style}>
                            <InputLabel id="demo-simple-select-label">
                                Modelo
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={valueModel}
                                defaultValue={valueModel}
                                label="Modelo"
                                onChange={handleChangeModel}
                            >
                                {models.map((item) => {
                                    return (
                                        <MenuItem key={item?._id} value={item?._id}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        
                    </Box>
                    <Box style={style}>
                        <DatePicker
                            views={["year"]}
                            label="Del:"
                            value={aplicacion.de}
                            onChange={(newValue) => {
                                setAplicacion({
                                    ...aplicacion,
                                    de: newValue,
                                });
                            }}
                            renderInput={(params) => (
                                <TextField {...params} helperText={null} />
                            )}
                        />
                    </Box>
                    <Box style={style}>
                        <DatePicker
                            views={["year"]}
                            label="Al:"
                            value={aplicacion.al}
                            onChange={(newValue) => {
                                setAplicacion({
                                    ...aplicacion,
                                    al: newValue,
                                });
                            }}
                            renderInput={(params) => (
                                <TextField {...params} helperText={null} />
                            )}
                        />
                    </Box>
                </Col>
                <Col className="mb-5 mb-xl-0">
                  
                  
                </Col>
               
            </Row>
            {
                isSending ? (
                    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <CircularProgress />
    </Box>
                ) : (
                    <Button variant="contained" fullWidth onClick={guardarMatch}>
                    Guardar
                </Button>
                )
            }

           
        </>
    );
};



